console.log('hello, world!');

// Hamburger

hamburgerMenu = function () {
	document.getElementById('hamburger').onclick = function(e) {    
	    if (document.getElementById('hamburger').classList.contains('hamburger--open') === false ) {
	        document.getElementById('navMenu').style.display = 'block';
	        document.getElementById('hamburger').classList.add('hamburger--open');
	        document.getElementById('7S-logo').classList.add('hamburger--open');
	        document.getElementById('main-header').classList.add('hamburger--open');
	    } else {
	        document.getElementById('navMenu').style.display = 'none';
	        document.getElementById('hamburger').classList.remove('hamburger--open');
	        document.getElementById('7S-logo').classList.remove('hamburger--open');
	        document.getElementById('main-header').classList.remove('hamburger--open');
	      }
	}
}

hamburgerMenu();


readMoreBio = function () {
	let button = $('#read-more');

	button.click(function(){
		$(this).next('.founder__copy').show();
	})
}

readMoreBio();